<template>
  <v-container>
    <v-layout
      wrap
    >
        <v-flex xs12 pa-3>
        <h1>Log Reader</h1>
        <p>Use this interface to view logs from the system.  This should be hidden unless you know where this is</p>
      </v-flex>
      <v-flex xs12 sm12>
        <h2>Viewing Logs for {{log}}</h2>
        <v-card>
        <v-card-title>
            
          <div class="flex-grow-1"></div>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="logitems"
          :search="search"
          :single-expand="singleExpand"
          :expanded.sync="expd"
          show-expand
          single-select
          item-key="id"
          :items-per-page="itemsperpage"
          sort-by="id"
          expand-icon="fa-caret-down"
          :sort-desc="sortdesc"
        >
          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length">
                <log :log="activeItem" v-on:update="update" v-on:close="close"></log>
            </td>
          </template>   
        </v-data-table>
      </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Log from '@/components/pieces/Log';
  export default {
    components:{
      "log":Log,
    },
    data: () => ({
      valid: true,
      dialog:false,
      thelog:{log:""},
      sortdesc:false,
      itemsperpage:100,
      expd:[],
      expansion:[],
      singleExpand: true,
      search:"",
      headers:[
        {
          text: 'Timestamp',
          align: 'left',
          sortable: true,
          value: 'timestamp',
        },
        {text:'Title', value:'title'},
        {text:'Type', value:'statustype'},
      ],
      logdata:"",
      checkbox: false,
      user:false,
      code:0
    }),
    created(){
      console.log("ROUTE",this.$route);
      this.$store.watch(() => this.$store.getters['loginStatus'],(data) => {
        // Callback here
        this.status = data;
        if(!this.status){
          this.$router.push({ path: '/login' })
        }
      });
      if(!this.$store.getters['loginStatus']){
        this.$router.push({ path: '/login' });
      }

      console.log("GO GET THE THING");

      console.log("HRRRMMMRM");
      let api = this.$store.getters['apiurl']+"logs/get/"+this.log;
      console.log("API URL",api);
      this.dialog=true;
      this.axios.get(api).then((response) => {
        this.dialog=false;
        if(response.data.code == 200){
          console.log("GOOD DATA",response.data);
          this.logdata = response.data.data;

        }else{
          this.logdata = false;
          this.code = 404;
        }
        console.log(response.data)
      })
    },
    computed:{
      log(){
        return this.$route.params.log;
      },
      logitems(){
        let itemlist=[];
        let items = this.logdata.split("\n");
        
        if(items.length === 1 && items[0].length === 0){
          return [];
        }

        for(let x in items){
          let re = /\[[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]\]/g;
          let obj = {};
          let item = items[x];
          let t = [];
          let ts = (t = re.exec(item)) ? t[0] : false;
          if(item.length > 5){
            obj.timestamp = (ts) ? ts.replace("[","").replace("]","") : false;
            obj.text = item.replace(ts,"").replace("customerservice.","");
            obj.statustype = (item.search(".WARNING") >= 0) ? "warning" : (item.search(".ERROR")>= 0) ? "error" : "info";
            obj.statustype = obj.statustype.toUpperCase();
            obj.id = x;
            let tarr = obj.text.split("[]");
            obj.title = tarr[0];
            obj.body = tarr[1];
            itemlist.push(obj);
          }
        }

        console.log("ITEMLIST",itemlist);
        return itemlist;
      },
      activeItem(){
        if(this.expd.length == 0){
          return {};
        }
        return this.expd[0];
      }
    },
    methods: {
      validate () {
        
      },
      close () {
        
      },
      update () {

      }
    }
  }
</script>

<style>

</style>
