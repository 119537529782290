<template>
  <v-container>
      <v-row>
          <v-col cols="12">
      <p>
      <strong>Click or tap</strong> the switches below to choose your newsletters.</p>
          </v-col>
      </v-row>
        <v-row>
            <v-col cols=4>
                <v-row v-for="(newsletter,index) in newsletters" v-bind:key="newsletter.Name" class="">
                    <newsletter :newsletter="newsletter" v-on:update-preference="updatePreference" class="preferences-checkbox" v-if="index%3===0 "/>
                </v-row>
            </v-col>
            <v-col cols=4>
                <v-row v-for="(newsletter,index) in newsletters" v-bind:key="newsletter.Name" class="">
                    <newsletter :newsletter="newsletter" v-on:update-preference="updatePreference" class="preferences-checkbox" v-if="index%3==1 "/>
                </v-row>
            </v-col>
            <v-col cols=4>
                <v-row v-for="(newsletter,index) in newsletters" v-bind:key="newsletter.Name" class="">
                    <newsletter :newsletter="newsletter" v-on:update-preference="updatePreference" class="preferences-checkbox" v-if="index%3===2 "/>
                </v-row>
            </v-col>
        </v-row>
          
  </v-container>
</template>

<script>
  import NSSwitch from '../pieces/NewsletterSwitch.vue';
  export default {
    name: 'Preferences',
    props:['activeuser'],
    components:{
        newsletter:NSSwitch
    },
    data(){

        return {
            newsletters:[],
            email:"",
            paid:false,
            code:200
        }
    },
    created(){
        console.log("Preferences Created")
        this.email=this.activeuser.EmailAddress;
        console.log()

        for(var x in this.activeuser.CustomFields){
            let field = this.activeuser.CustomFields[x];
            if(field.Key==="Subscriber Type" && (field.Value=="print" || field.Value=="digital")){
                this.paid=true;
            }
        }

        this.getNewsletters();
    },

    mounted(){
        console.log("Preferences Mounted")
    },

    methods:{
        getNewsletters(){
            let email = this.email;
            //let api = "https://apis.post-gazette.com/cm_email/subscriber/newsletters/"+encodeURIComponent(email)+"/";
            let api = "http://customerservice.post-gazette.com/api/email/newsletters/"+encodeURIComponent(email)+"/";
            let that = this;
            this.axios.get(api).then((response) => {   
                if(response.data.code == 200){
                    console.log("GOOD DATA",response.data.data.data);
                    that.newsletters = response.data.data.data.newsletters;
                    for(var x in that.newsletters){
                        let newsletter = that.newsletters[x];
                        if(newsletter.paid==='True' && that.paid!==true){
                           that.newsletters.splice(x,1);
                        }
                    }
                }else{
                    that.code = 404;
                }
                console.log(response.data)
            })
        },
        updatePreference(data){
            console.log("EVENT FOUND",data);
            let email = this.email;
            //let api = "https://apis.post-gazette.com/cm_email/subscriber/update/";
            let api = "http://customerservice.post-gazette.com/api/email/subscriber/update/"
            let obj = {"ConsentToTrack":"Unchanged"};
            obj.email = encodeURIComponent(email);

            if(data.value  === true){
                obj.subscribe=[];
                obj.subscribe.push(data.key);
            }else{
                obj.unsubscribe=[];
                obj.unsubscribe.push(data.key);
            }
            console.log("Payload ",obj);
            this.axios.post(api,obj).then((response) => {
                this.dialog=false;
                console.log(response);
                if(response.data.code == 200){
                    console.log("GOOD DATA",response.data);
                }else{
                    console.log("SOME ERROR",response.data);
                }
            })
        }
    }
  }
</script>
