<template>
  <v-container>
    <v-layout
      wrap
    >
      <v-flex xs12 pa-3>
        <h1>Link Account</h1>
        <p>Use this interface to manually link a gigya user to an existing Cust ID.</p>
      </v-flex>
        <v-flex xs12 pa-3>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >

          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Customer Email"
            required
          ></v-text-field>
          <v-text-field
            v-model="accountnumber"
            label="Account Number"
            required
          ></v-text-field>
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="validate"
          >
            Submit
          </v-btn>
        </v-form>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :multi-line="false"
      :top="true"
      color="error"
    >
      {{ snackbarText }}
      <v-btn
        color="white"
        flat
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>


    <v-snackbar
      v-model="toast"
      :multi-line="false"
      :top="true"
      color="success"
    >
      {{ snackbarText }}
      <v-btn
        color="white"
        flat
        @click="toast = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      valid: true,
      dialog:false,
      accountnumber: '',
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      user:false,
      toast:false,
      code:0,
      error:false,
      snackbar:false,
      snackbarText:"Invalid Form"
    }),
    created(){
      this.$store.watch(() => this.$store.getters['loginStatus'],(data) => {
        // Callback here
        this.status = data;
        if(!this.status){
          this.$router.push({ path: '/login' })
        }
      });
      if(!this.$store.getters['loginStatus']){
        this.$router.push({ path: '/login' });
      }
    },

    methods: {
      postData(url = ``, data = {}){
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Content-Type": "application/json",
                "Authorization":localStorage.getItem("_pg_cs_access"),
                "From":localStorage.getItem("_pg_cs_email")
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(response => response.json()); // parses JSON response into native Javascript objects 
      },
      validate () {
        this.error = false;
        if (!this.$refs.form.validate()) {
          this.snackbar = true;
          return false;
        }


        let api = this.$store.getters['apiurl']+"user/link";
        this.dialog=true;
        this.postData(api,{email:this.email,subID:this.accountnumber}).then((response) => {
          this.dialog=false;
          console.log("YO",response);
          if(response.code == 200){
            this.user = response.data;
            this.toast=true;
            this.snackbarText="Account Successfully Linked";
            //this.reset();
          }else{
            this.user = false;
            this.code = 404;
            this.snackbar = true;
            this.snackbarText = response.message;
          }
          console.log(response.message)
        })
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      }
    }
  }
</script>

<style>

</style>
