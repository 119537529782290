<template>
  <v-container>
    <v-alert
      v-model="alert"
      border="left"
      close-text="Close Alert"
      color="black accent-4"
      dark
      dismissible
    >
      URL Copied to Clipboard
    </v-alert>
    <v-form>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="template"
            label="Template ID"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="offer"
            label="Offer ID"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="campaign"
            label="Campaign"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-btn color="success" @click="buildUrl"><v-icon>fa-search</v-icon> Go</v-btn>
      </v-row>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-row v-show="result.length > 0" @click="copyText"><v-text-field
            v-model="result"
            label="Result"
            id="built_url"
          ></v-text-field></v-row>
    </v-container>
  </v-form>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      valid: false,
      dialog:false,
      alert:false,
      offer: '',
      template: '',
      campaign: '',
      result:""
    }),
    created(){
      console.log(this);
    },
    methods: {
      validate () {
        
      },
      copyText () {
          let textToCopy = document.querySelector('#built_url');
          textToCopy.select()
          console.log(textToCopy)
          document.execCommand("copy");
          this.alert=true;
      },
      buildUrl () {
          this.result="https://my.post-gazette.com/purchase/promo/?offer="+this.offer+"&template="+this.template+"&tpcc="+encodeURIComponent(this.campaign);
      }
    }
  }
</script>

<style>

</style>
