<template>
  <v-container>
    <v-layout
      text-xs-center
      wrap
    >
      <v-flex xs12 align-content-center>
        <v-img
          :src="logo"
          class="my-3"
          contain
          max-height="150"
          max-width="50%"
          style="margin:10px auto"
        ></v-img>
      </v-flex>

      <v-flex mb-4 text-center>
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Vantage 
        </h1>
        <h3 class="mb-3">The Digital Team's Customer Service Tool</h3>
        <p class="subheading font-weight-regular">
          Lookup subscriber accounts, update passwords,<br />
          change email addresses and link paid accounts using this tool.
        </p>
      </v-flex>

      <v-flex
        mb-5
        xs12
      >
        <v-list two-line>
        <v-list-item v-for="item in navlinks" @click="" :to="{ name: item.path }">
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
            <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>



      </v-flex>

      
    </v-layout>
  </v-container>
</template>




<script>
  export default {
    data: () => ({
      navlinks: [
        {
          text: 'Subscriber Lookup',
          path: 'subscribers.list',
          icon: 'fa-id-card',
          description: 'Lookup a user\'s profile.  Use this to update email addresses, link accounts and change passwords.'
        }
      ]
    }),
    computed:{
      logo(){
        console.log(this.$store.getters['logo']);
        return this.$store.getters['logo'];
      },
    }
  }
</script>

<style>

</style>
