<template>
  <v-container>
    <v-layout
      wrap
    >
        <v-flex xs12 pa-3>
        <h1>Lookup User</h1>
        <p>Use this interface to lookup a user's account information.  CUID searches the dti subscriber file for information, email searches the Gigya file, while search queries the gigya API for what's currently active.</p>
      </v-flex>
        <v-flex xs12 sm4 text-left pa-3>
          <v-sheet color="grey lighten-3" height="424">
            <h2 class="pt-3 pl-3 pr-3">USER INFO</h2>
            <div v-if="user" class="pa-3">
              <v-layout row><v-flex xs6>Name</v-flex><v-flex xs6>{{user.firstname}} {{user.lastname}} </v-flex></v-layout>
              <v-layout row><v-flex xs6>OccupantID </v-flex><v-flex xs6>{{user.occupantID}}</v-flex></v-layout>
              <v-layout row><v-flex xs6>AddressID </v-flex><v-flex xs6>{{user.addressID}}</v-flex></v-layout>
              <v-layout row><v-flex xs6>cUID </v-flex><v-flex xs6>{{user.cuid}}</v-flex></v-layout>
              <v-layout row><v-flex xs6>Email </v-flex><v-flex xs6>{{user.email}}</v-flex></v-layout>
              <v-layout row><v-flex xs6>SUID </v-flex><v-flex xs6>{{user.suid}}</v-flex></v-layout>
              <v-layout row><v-flex><a :href="syncronexLink+user.suid" target="_blank">Syncronex Link</a></v-flex></v-layout>
            </div>
            <div v-if="!user && code==0" class="pa-3">
              <p>Fill out the form to the right to lookup a user</p>
            </div>
            <div v-if="!user && code==404" class="pa-3">
              <p>User Not Found.  Please try again</p>
            </div>
          </v-sheet>
        </v-flex>
        <v-flex xs12 sm8>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="CUID"
            v-if="select==='cuid'"
            required
          ></v-text-field>

          <v-text-field
            v-model="name"
            :rules="emailRules"
            label="Email"
            v-if="select==='email' || select ==='search'"
            required
          ></v-text-field>

          <v-radio-group v-model="select" row>
            <v-radio
              v-for="n in items"
              :key="n"
              :label="n"
              :value="n"
            ></v-radio>
          </v-radio-group>

          <v-btn
            :disabled="!valid"
            color="primary"
            @click="validate"
          >
            Get User
          </v-btn>
        </v-form>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      valid: true,
      dialog:false,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 4) || 'Name must be less than 10 characters'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      select: 'cuid',
      items: [
        'cuid',
        'email',
        'search'
      ],
      checkbox: false,
      user:false,
      code:0
    }),
    created(){
      this.$store.watch(() => this.$store.getters['loginStatus'],(data) => {
        // Callback here
        this.status = data;
        if(!this.status){
          this.$router.push({ path: '/login' })
        }
      });
      if(!this.$store.getters['loginStatus']){
        this.$router.push({ path: '/login' });
      }
    },
    computed:{
      syncronexLink(){
        return this.$store.getters['syncronexStem']+'/SubscriberDetail/Index/';
      }
    },
    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
        console.log("HRRRMMMRM");
        let api = this.$store.getters['apiurl']+"lookup/"+this.select+"/"+this.name;
        console.log("HEEEEYYYY",this.name);
        console.log(api);
        this.dialog=true;
        this.axios.get(api).then((response) => {
          this.dialog=false;
          if(response.data.code == 200){
            this.user = response.data.data;
          }else{
            this.user = false;
            this.code = 404;
          }
          console.log(response.data)
        })
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      }
    }
  }
</script>

<style>

</style>
