<template>
  <v-container>
    <v-layout
      wrap
    >
        <v-flex xs12 pa-3>
        <h1>Change Password</h1>
        <p>Use this interface to manually change a user's login password.   The user's existing email must be present in the Gigya subscriber file</p>
      </v-flex>
        <v-flex xs12 pa-3>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >

          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Email"
            required
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Password"
            required
          ></v-text-field>
          <v-text-field
            v-model="confirm"
            :rules="confirmRules"
            label="Confirm Password"
            required
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            color="primary"
            @click="validate"
          >
            Submit
          </v-btn>
        </v-form>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :multi-line="false"
      :top="true"
      color="error"
    >
      {{ snackbarText }}
      <v-btn
        color="white"
        flat
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>


    <v-snackbar
      v-model="toast"
      :multi-line="false"
      :top="true"
      color="success"
    >
      {{ snackbarText }}
      <v-btn
        color="white"
        flat
        @click="toast = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      valid: true,
      dialog:false,
      password: '',
      confirm: '',
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be greater than 10 characters'
      ],
      confirmRules: [
        v => !!v || 'Confirm the changed password',
        v => (v && v.length >= 6) || 'Password must be greater than 10 characters'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      select: 'cuid',
      items: [
        'cuid',
        'email'
      ],
      checkbox: false,
      user:false,
      toast:false,
      code:0,
      error:false,
      snackbar:false,
      snackbarText:"Some Error Text"
    }),
    created(){
      this.$store.watch(() => this.$store.getters['loginStatus'],(data) => {
        // Callback here
        this.status = data;
        if(!this.status){
          this.$router.push({ path: '/login' })
        }
      })

      if(!this.$store.getters['loginStatus']){
        this.$router.push({ path: '/login' });
      }
    },

    methods: {
      postData(url = ``, data = {}){
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Content-Type": "application/json",
                "Authorization":localStorage.getItem("_pg_cs_access"),
                "From":localStorage.getItem("_pg_cs_email")
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(response => response.json()); // parses JSON response into native Javascript objects 
      },
      validate () {
        this.error = false;
        if (!this.$refs.form.validate()) {
          this.snackbar = true
        }

        if(this.password !== this.confirm){
          console.error("Passwords Do Not Match")
          this.snackbar = true;
          this.error = true;
          this.snackbarText="Passwords Do Not Match";
        }else{
          let api = this.$store.getters['apiurl']+"user/password/reset";
          this.dialog=true;
          this.postData(api,{email:this.email,pass:this.password}).then((response) => {
            console.log("YO",response);
            this.dialog=false;
            if(response.code == 200){
              this.user = response.data.data;
              this.snackbar=true;
              this.snackbarText="Password Updated";
              this.reset();
            }else{
              this.user = false;
              this.code = 404;
              this.snackbar = true;
              this.snackbarText = response.message;
            }
            console.log(response.message)
          })
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      }
    }
  }
</script>

<style>

</style>
