<template>
  <v-container>
    <v-layout
      wrap
    >
        <v-flex xs12 pa-3>
        <h1>Users</h1>
        <p>Use this interface to manage users of the vantage system</p>
      </v-flex>
      <v-flex xs12 sm12>

        <v-card>
          <v-card-title>
            
            <div class="flex-grow-1"></div>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="logs"
            :search="search"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            item-key="email"
          >
            <template v-slot:expanded-item="{ headers }">
              <td :colspan="headers.length">
                  <trialuser :activeuser="activeUser" v-on:cancel="cancel"></trialuser>
              </td>
            </template>   
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import TrialUser from '@/components/pieces/TrialUser';

  export default {
    components:{
      "trialuser":TrialUser,
    },
    data: () => ({
      valid: true,
      dialog:false,
      expanded:[],
      singleExpand: true,
      search:"",
      headers:[
          { text: 'Email', value: 'email' },
          { text: 'Name', value: 'name' },
          { text: 'Level', value: 'level' }
        ],
      logs:[],
      checkbox: false,
      user:false,
      code:0
    }),
    created(){
      console.log(this);
      this.$store.watch(() => this.$store.getters['loginStatus'],(data) => {
        // Callback here
        this.status = data;
        if(!this.status){
          this.$router.push({ path: '/login' })
        }
      });
      if(!this.$store.getters['loginStatus']){
        this.$router.push({ path: '/login' });
      }

      console.log("GO GET THE THING");

      console.log("HRRRMMMRM");
      let api = this.$store.getters['apiurl']+"/admin/users/list";
      console.log("API URL",api);
      this.dialog=true;
      this.axios.get(api).then((response) => {
        this.dialog=false;
        if(response.data.code == 200){
          console.log("GOOD DATA",response.data);
          this.logs = this.processLogs(response.data.data);

        }else{
          this.user = false;
          this.code = 404;
        }
        console.log(response.data)
      })
    },
    computed:{
      activeUser(){
        if(this.expanded.length == 0){
          return {};
        }
        return this.expanded[0];
      }
    },
    methods: {
      validate () {
        
      },
      reset () {
        
      },
      resetValidation () {

      },
      processLogs(logs){
        for(var x in logs){
          let log = logs[x];
          let d = new Date(log.dateTime)
          d.setDate(d.getDate()+28);
          log.expires = d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate();
        }
        return logs;
      },
      cancel(user){
        console.log("Cancelling user ",user);
        let api = this.$store.getters['apiurl']+"trials/cancel";
        this.dialog=true;
        this.axios.post(api,{id:user.transactionId}).then((response) => {
          this.dialog=false;
          if(response.data.code == 200){
            console.log("GOOD DATA",response.data);
            this.logs = this.processLogs(response.data.data);
            this.$emit('alert','Trial Cancelled');
          }else{
            this.$emit('error',response.data.code+": "+response.data.message);
            console.log("SOME ERROR");
          }
        })
      }
    }
  }
</script>

<style>

</style>
