import Vue from 'vue'
import axios from './plugins/axios'
import App from './App.vue'
import router from './router'
import {store} from './store'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: h => h(App),
  created(){
    var that = this;
    that.$store.commit('saveMode',window.vantagemode);
    window.apiurl = that.$store.getters['apiurl'];
    
    window._CSAPP.eventer.listen("statechange",function(){
    	that.$store.commit('refresh');

    	if(that.$store.getters['loginStatus']){
    		that.axios.defaults.headers.common['Authorization'] = localStorage.getItem("_pg_cs_access");
			that.axios.defaults.headers.common['From'] = localStorage.getItem("_pg_cs_email");
    	}else{
    		delete that.axios.defaults.headers.common['Authorization'];
			delete that.axios.defaults.headers.common['From']
    	}
    });

    router.beforeEach((to, from, next) => {
      var loggedIn = that.$store.getters['loginStatus'];
      var adminStatus = that.$store.getters['isAdmin'];
      console.log("LOGIN STATUS ",loggedIn," ADMIN STATUS ",adminStatus);

      if(loggedIn && to.meta.requiresNoAuth){
        console.log("ABORTING BECAUSE LOGGED IN ")
        window._CSAPP.eventer.trigger("notify",{message:"You must login to complete this action"})
        next("/");
      }

      if(!loggedIn && to.meta.requiresAuth){
        console.log("ABORTING BECAUSE NOT LOGGED IN ");
        window._CSAPP.eventer.trigger("notify",{message:"You must login to complete this action"})
        next("/login");
      }

      next();
      // `to` and `from` are both route objects
    })

    router.afterEach((to, from) => {
      // ...
      console.log("NAVIGATING TO ",to," FROM ",from);
      window.scrollTo(0, 0);
      window._CSAPP.eventer.trigger("navigate",to);
    })
  },
  mounted(){
  	var that = this;
  	if(window._CSAPP.lib.auth.loggedIn()){
  		console.log("LOGGED IN");
  		if(that.$store.getters['loginStatus']){
    		that.axios.defaults.headers.common['Authorization'] = localStorage.getItem("_pg_cs_access");
			that.axios.defaults.headers.common['From'] = localStorage.getItem("_pg_cs_email");
    	}else{
    		delete that.axios.defaults.headers.common['Authorization'];
			delete that.axios.defaults.headers.common['From']
    	}
  	}else{
      console.log("NOT LOGGED IN")
      that.$router.push({ name: 'login'})
    }

  	
  }
}).$mount('#app')





