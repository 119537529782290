<template>
  <v-container>
    <v-layout
      wrap
      v-show="subscriber.email"
    >
      <v-flex xs12 sm3 pa-3>
        <v-card>
        <div class="text-center pa-3">
          <p class="text-center"><v-icon size='80' center>fa-user-circle</v-icon></p>
          <h3>{{subscriber.first_name}} {{subscriber.last_name}}</h3>
          <div class="text-muted">{{subscriber.email}}</div>
          <div class="body-2 font-weight-thin">{{subscriber.uid}}</div>
          <div class="body-2"><a :href="subscriber.pianoLink" target="piano_blank">Piano Link</a></div>
        </div>
        <v-dialog v-model="activatedialog" persistent max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on" text>Link Account</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{heading}}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
              <v-row>
                <v-flex xs12 md12 v-show="!custAccount.subType">
                  <v-form
                  ref="form"
                  v-model="activatevalid"
                  lazy-validation
                  
                >
                  <v-text-field
                    v-model="accountnumber"
                    label="Account Number*"
                    :rules="accountRules"
                    required
                  ></v-text-field>
                  <v-btn
                    :disabled="!activatevalid"
                    color="primary"
                    @click="getcustaccount"
                  >
                    Submit
                  </v-btn>
                </v-form>
                <small>*indicates required field</small>
                </v-flex>
                <v-flex xs12 md12>
                  <div v-show="custAccount.subType">
                    
                    <v-flex xs12>
                      <v-list dense>
                        <v-list-item>
                          <h3>{{custAccount.firstName}} {{custAccount.lastName}}</h3>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Account ID</v-list-item-title>
                            <v-list-item-subtitle>{{custAccount.circID}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Is Active?</v-list-item-title>
                            <v-list-item-subtitle>{{custAccount.isActive}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Phone Number</v-list-item-title>
                            <v-list-item-subtitle>{{custAccount.phone}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>Contact Info:</v-list-item-title>
                            <v-list-item-subtitle><div>{{custAccount.address1}}</div>
                      <div>{{custAccount.address2}}</div>
                      <div>{{custAccount.city}}, {{custAccount.state}} {{custAccount.zip}}</div></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-layout row wrap>
                        <v-flex xs12 sm6 class="pa-3">
                        <v-btn
                        color="error"
                        @click="incorrectAccount()"
                      >
                        Back to Search
                      </v-btn>
                      </v-flex>
                      <v-flex xs12 sm6 class="pa-3">
                          <v-btn
                            color="success"
                            @click="linkAccount()"
                          >
                            Activate Account
                          </v-btn>
                      </v-flex>
                      </v-layout>
                      
                      
                      
                    </v-flex>
                    
                  </div>
                  <div v-show="!custAccount.subType">
                    <h3>{{custmessage}}</h3>
                  </div>
                  <div v-show="!error.length == 0">
                    <h3 color="error--text" class="error--text">{{error}}</h3>
                  </div>
                </v-flex>
               
              </v-row>
            </v-container>
            
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="linkClose">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--v-dialog v-model="passwordchangedialog" scrollable persistent max-width="600px" transition="dialog-transition">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on" text>Change Password</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Change Password</span>
            </v-card-title>
            <v-card-text>
              <p>Use this interface to manually change a user's login password.</p>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  label="Password"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="confirm"
                  :rules="confirmRules"
                  label="Confirm Password"
                  required
                ></v-text-field>

                <v-btn
                  :disabled="!valid"
                  color="primary"
                  @click="validate"
                >
                  Submit
                </v-btn>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="passwordClose">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog -->

        <!--v-dialog v-model="emailchangedialog" scrollable persistent max-width="600px" transition="dialog-transition">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on" text>Change Email</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Change Email Address</span>
            </v-card-title>
            <v-card-text>
              <p>Use this interface to manually change a user's login email.</p>
              <v-form
                ref="eform"
                v-model="evalid"
                lazy-validation
              >
                <v-text-field
                  v-model="newemail"
                  :rules="emailRules"
                  label="New Email"
                  required
                ></v-text-field>
                <v-btn
                  :disabled="!evalid"
                  color="primary"
                  @click="validateEmail"
                >
                  Submit
                </v-btn>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="emailClose">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog-->


        <v-list dense>
          <!--v-list-item>
            <v-list-item-content>
              <v-list-item-title>Last Login</v-list-item-title>
              <v-list-item-subtitle>{{subscriber.lastLogin}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Last Update</v-list-item-title>
              <v-list-item-subtitle>{{subscriber.lastUpdated}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item-->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Created</v-list-item-title>
              <v-list-item-subtitle>{{subscriber.created}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      </v-flex>
      <v-flex xs12 sm9 sm12 pa-3>
        <v-card elevation=0>
      <v-card-text>
        <v-layout row wrap>
            <v-flex xs12 sm6>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Email UID</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.profile.emailUID}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Active Paid User</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.profile.activePaidUser}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Address Id</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.profile.addressId}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Occupant Id</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.profile.occupantId}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>CUID</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.profile.circID}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>SUID</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.profile.sUID}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Piano ID</v-list-item-title>
                    <v-list-item-subtitle v-text="subscriber.uid"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Plan Code</v-list-item-title>
                    <v-list-item-subtitle v-text="subscriber.profile.rateCode"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                </v-list>
              </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="toast"
      :multi-line="false"
      :top="true"
      color="success"
    >
      {{ snackbarText }}
      <v-btn
        color="white"
        text
        @click="toast = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbar"
      :multi-line="false"
      :top="true"
      color="error"
    >
      {{ snackbarText }}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
     
</template>

<script>
  //import TrialUser from '@/components/pieces/TrialUser'
  export default {
    props:['uid'],
    data: () => ({
      activatevalid:false,
      heading:"Link Account",
      valid: true,
      evalid:true,
      dialog:false,
      toast:false,
      snackbar:false,
      passwordchangedialog:false,
      emailchangedialog:false,
      snackbarText:"Invalid Form",
      activatedialog:false,
      accountnumber:"",
      password:"",
      confirm:"",
      newemail:"",
      error:"",
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be greater than 10 characters'
      ],
      confirmRules: [
        v => !!v || 'Confirm the changed password',
        v => (v && v.length >= 6) || 'Password must be greater than 10 characters'
      ],
      subscriber:{data:{},profile:{}},
      custAccount:{},
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      accountRules: [
        v => !!v || 'Account # is required',
        v => (v && Boolean(v.match(/^[0-9]*$/))) || 'Account # must be numbers only',
      ],
      custmessage:"Enter a valid cust account number"
    }),
    created(){
      console.log("UID IS ",this.uid)
      //let apiurl = "http://localhost/~nate/pg/vantage/api";
      let api = apiurl+"search/general/uid/"+this.uid;;
      console.log("API URL",api);
      this.dialog=true;
      this.axios.get(api).then((response) => {
        this.dialog=false;
        if(response.data.code == 200){
          console.log("GOOD DATA",response.data);
          //this.logs = this.processLogs(response.data.data);
          this.subscriber = response.data.data;
          this.subscriber.profile = {};
          for(var x in this.subscriber.custom_fields){
            var field = this.subscriber.custom_fields[x];
            this.subscriber.profile[field.fieldName] = field.value;
          }
          this.subscriber.pianoLink = "https://dashboard.piano.io/publisher/user/edit/subscribers/overview/"+this.subscriber.uid;
        }else{
          this.user = false;
          this.code = 404;
        }
        console.log(response.data)
      })

    },
    methods: {
      getcustaccount () {
        console.log("get account",this.accountnumber);
        //let apiurl = "http://localhost/~nate/pg/vantage/api";
        let api = apiurl+"/search/cuid/"+this.accountnumber;
        console.log("API URL",api);
        this.dialog=true;
        this.error="";
        this.axios.get(api).then((response) => {
          this.dialog=false;
          if(response.data.code == 200){
            console.log("GOOD DATA",response.data);
            //this.logs = this.processLogs(response.data.data);
            let acc = response.data.data.pop();
            console.log(acc)
            this.custAccount = acc;
            this.heading = "Is This User Correct?"
          }else{
            this.custAccount = {};
            this.code = 404;
            console.log("ERROR",response.data.message);
            this.error = response.data.message
          }
          console.log(response.data)
        })
      },
      postData(url = ``, data = {}){
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Content-Type": "application/json",
                "Authorization":localStorage.getItem("_pg_cs_access"),
                "From":localStorage.getItem("_pg_cs_email")
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(response => response.json()); // parses JSON response into native Javascript objects 
      },
      linkClose(){
        this.activatedialog = false;
        this.accountnumber="";
        this.custAccount = {};
        this.error="";
      },

      passwordClose(){
        this.passwordchangedialog = false;
        this.password = "";
        this.confirm = "";
        this.error="";
      },
      emailClose(){
        this.emailchangedialog = false;
        this.newemail="";
        this.error="";
      },
      incorrectAccount(){
        this.custAccount = {};
        this.error="";
      },
      linkAccount(){
        console.log("LINKING ACCOUNT FOR ",this.accountnumber,this.custAccount, " WITH ",this.subscriber);
        let api = this.$store.getters['apiurl']+"user/linkit";
        this.dialog=true;
        this.postData(api,{cUID:this.custAccount.circID,UID:this.subscriber.uid}).then((response) => {
          this.dialog=false;
          console.log("YO",response);
          if(response.code == 200){
            this.user = response.data;
            this.toast=true;
            this.snackbarText="Account Successfully Linked";
            this.reset();
            let api = apiurl+"search/general/uid/"+this.uid;
            console.log("API URL",api);
            this.dialog=true;
            this.axios.get(api).then((response) => {
              this.dialog=false;
              if(response.data.code == 200){
                console.log("GOOD DATA",response.data);
                //this.logs = this.processLogs(response.data.data);
                this.subscriber = response.data.data;
                this.subscriber.profile = {};
                for(var x in this.subscriber.custom_fields){
                  var field = this.subscriber.custom_fields[x];
                  this.subscriber.profile[field.fieldName] = field.value;
                }
                this.subscriber.pianoLink = "https://dashboard.piano.io/publisher/user/edit/subscribers/overview/"+this.subscriber.uid;
              }else{
                this.user = false;
                this.code = 404;
              }
              console.log(response.data)
            })
          }else{
            this.user = false;
            this.code = 404;
            this.snackbar = true;
            this.snackbarText = response.message;
          }
          console.log(response.message)
        })
      },
      cancel () {
       
      },
      reset () {
        this.linkClose();
        this.passwordClose();
        this.emailClose();

      },
      resetValidation () {

      },
      gotouser(){
        console.log("GO TO USER ",this.uid);
      },
      validate () {
        this.error = false;
        if (!this.$refs.form.validate()) {
          this.snackbar = true
        }

        if(this.password !== this.confirm){
          console.error("Passwords Do Not Match")
          this.snackbar = true;
          this.error = true;
          this.snackbarText="Passwords Do Not Match";
        }else{
          let api = this.$store.getters['apiurl']+"user/password/reset";
          this.dialog=true;
          console.log("Changing password to ",this.password," from ", this.confirm);
          this.postData(api,{email:this.subscriber.profile.email,pass:this.password}).then((response) => {
            console.log("YO",response);
            this.dialog=false;
            if(response.code == 200){
              //this.subscriber = response.data;
              this.toast=true;
              this.snackbarText="Password Updated";
              this.reset();
            }else{
              this.user = false;
              this.code = 404;
              this.snackbar = true;
              this.snackbarText = response.message;
            }
            console.log(response.message)
          })
        }
      },
      validateEmail () {
        this.error = false;
        if (!this.$refs.eform.validate()) {
          this.snackbar = true;
          return false;
        }


        let api = this.$store.getters['apiurl']+"user/email/change";
        this.dialog=true;
        this.postData(api,{email:this.subscriber.profile.email,newemail:this.newemail,UID:this.subscriber.UID}).then((response) => {
          this.dialog=false;
          console.log("YO",response);
          if(response.code == 200){
            this.subscriber = response.data;
            this.toast=true;
            this.snackbarText="Email Successfully Changed";
            this.reset();
            //this.reset();
          }else{
            this.user = false;
            this.code = 404;
            this.snackbar = true;
            this.snackbarText = response.message;
          }
          console.log(response.message)
        })
      }
    }
  }
</script>

<style>

</style>
