<template>
  <v-container>
    <v-layout
      wrap
    >
        <v-flex xs12 pa-3>
        <h1>Email User Search</h1>
        <p>Use this interface to search for a user's Campaign Monitor account</p>
      </v-flex>
      <v-flex xs12 sm12>

        <v-card>
          <v-card-title>
            
            <div class="flex-grow-1"></div>
            <v-text-field
              v-model="search"
              label="Email Address"
              single-line
              hide-details
            ></v-text-field>
            <v-btn color="success" @click="searchUser"><v-icon>fa-search</v-icon> Search</v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="logs"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            item-key="email_address"
          >
            <template v-slot:expanded-item="{ headers }">
              <td :colspan="headers.length">
                  <trialuser :activeuser="activeUser" v-on:cancel="cancel"></trialuser>
              </td>
            </template>   
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  //import Subscriber from '@/components/pieces/SubscriberPreview';
  import TrialUser from '@/components/pieces/EmailUser';

  export default {
    components:{
      "trialuser":TrialUser,
    },
    data: () => ({
      valid: true,
      dialog:false,
      expanded:[],
      singleExpand: true,
      search:"",
      searchMode:"email",
      headers:[
          { text: 'Email', value: 'email_address' },
          { text: 'Name', value: 'Name' },
          { text: 'Status', value: 'Status' }
        ],
      logs:[],
      checkbox: false,
      user:false,
      code:0
    }),
    created(){
      console.log(this);
      this.$store.watch(() => this.$store.getters['loginStatus'],(data) => {
        // Callback here
        this.status = data;
        if(!this.status){
          this.$router.push({ path: '/login' })
        }
      });
      if(!this.$store.getters['loginStatus']){
        this.$router.push({ path: '/login' });
      }

      console.log("GO GET THE THING");

      console.log("HRRRMMMRM");
      
    },
    computed:{
      activeUser(){
        if(this.expanded.length == 0){
          return {};
        }
        return this.expanded[0];
      }
    },
    methods: {
      searchUser(){
        console.log("SEARCH USER",this.search);
        //let apiurl = "http://localhost/~nate/pg/vantage/api";
        //let api = "https://apis.post-gazette.com/cm_email/subscriber/search/"+encodeURIComponent(this.search)+"/";
         let api = "https://apis.post-gazette.com/cm_email/subscriber/search/";
        //let api = apiurl+"/search/general/"+this.searchMode+"/"+encodeURIComponent(this.search);
        console.log("API URL",api);
        this.dialog=true;

      var that = this;
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
          if (this.readyState != 4) return;

          if (this.status == 200) {
              that.dialog=false;
              var response = JSON.parse(this.responseText);
              console.log("THE Response",response);
              if(response.code == 200){
                console.log("GOOD DATA",response.data);
                that.logs = that.processLogs(response.data);

              }else{
                that.user = false;
                that.code = 404;
              }
              // we get the returned data
          }

          // end of state change: it can be after some time (async)
      };
      xhr.open("POST", api, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify({"term":this.search}));







        /*this.axios.get(api,{"term":this.search}).then((response) => {
          this.dialog=false;
          if(response.data.code == 200){
            console.log("GOOD DATA",response.data);
            this.logs = this.processLogs(response.data.data);

          }else{
            this.user = false;
            this.code = 404;
          }
          console.log(response.data)
        })*/



      },
      validate () {
        
      },
      reset () {
        
      },
      resetValidation () {

      },
      processLogs(data){
        var logs = [];
        //var u = {};
        for(var x in data){
          let user = data[x];
          logs.push(user);
        }
        /*for(var x in logs){
          let log = logs[x];
          log.name = 
          log.email = log.email;
          let d = new Date(log.lastUpdatedTimestamp);
          // Hours part from the timestamp
          let hours = d.getHours();
          // Minutes part from the timestamp
          let minutes = "0" + d.getMinutes();
          // Seconds part from the timestamp
          let seconds = "0" + d.getSeconds();
          let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

          log.lastUpdated = d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate()+" "+formattedTime;
        }*/

        console.log("LOGS",logs);
      
        return logs;
      },
      cancel(){
        
      }
    }
  }
</script>

<style>

</style>
