<template>
 

    <v-card elevation=0>
      <v-card-title>{{activeuser.deliveryNameFirst}} {{activeuser.deliveryNameMiddle}} {{activeuser.deliveryNameLast}}</v-card-title>
      <v-card-text>
        <v-layout row wrap>
            <v-flex xs12 sm6>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Email Address</v-list-item-title>
                    <v-list-item-subtitle>{{activeuser.customerEmail}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Street Address</v-list-item-title>
                    <v-list-item-subtitle>{{activeuser.deliveryAddress}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>City, State, Zip</v-list-item-title>
                    <v-list-item-subtitle>{{activeuser.deliveryCity}}, {{activeuser.deliveryState}} {{activeuser.deliveryZip}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Phone</v-list-item-title>
                    <v-list-item-subtitle>{{activeuser.deliveryTelephone}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>SUID</v-list-item-title>
                    <v-list-item-subtitle>{{activeuser.sUID}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Gigya ID</v-list-item-title>
                    <v-list-item-subtitle v-text="activeuser.gUID"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Plan Code</v-list-item-title>
                    <v-list-item-subtitle v-text="activeuser.planCode"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Transaction ID</v-list-item-title>
                    <v-list-item-subtitle v-text="activeuser.transactionId"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
        </v-layout>
        
      </v-card-text>
      <v-card-actions>


      <v-dialog v-model="dialog" persistent max-width="400">
        <template v-slot:activator="{ on }">
          <v-btn color="red" text dark v-on="on">Cancel Trial</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Cancel {{activeuser.deliveryNameFirst}} {{activeuser.deliveryNameMiddle}} {{activeuser.deliveryNameLast}}'s Free Trial?</v-card-title>
          <v-card-text>This function will place them on an exclusion list that won't let them be charged when the trial is up</v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="green darken-1" text @click="dialog = false">Don't Cancel</v-btn><v-spacer></v-spacer><v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false; cancel()">Cancel Trial</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
    </v-card>
     
</template>

<script>
  //import TrialUser from '@/components/pieces/TrialUser'
  /*

"dateTime": "2019-09-18 14:00:36",
"termAmount": "0",
"token": "059862013",
"transactionId": "154520190918130034889420",
"planId": "80",
"deliveryInst": "",
"customerEmail": "billybob@brian.net",
"sUID": "91526",



"effectiveStartMonth": "",
"effectiveStartDay": "",
"effectiveStartYear": "",
"gUID": "6add2565ee7046ddbb070ca32a5282d9",
"planName": "4 Week Free Trial - Override Rate",
"planCode": "PPGDGDG00O",
"bucket": "",
"credCardMask": "1796",
"credCardType": "MC",
"expirationDateMonth": "11",
"expirationDateYear": "19"
  */
  export default {
    props:['activeuser'],
    data: () => ({
      valid: true,
      dialog:false
    }),
    created(){
      
    },
    methods: {
      validate () {
        
      },
      cancel () {
        console.log("CANCEL ACTIVE USER",this.activeuser);
        this.$emit('cancel',this.activeuser);
      },
      resetValidation () {

      }
    }
  }
</script>

<style>

</style>
