<template>
  <v-container>
    <v-layout
      wrap
    >
        <v-flex xs12 pa-3>
        <h1>Support Issues</h1>
        <p>Use this interface to easily view issues from the support spreadsheet</p>
      </v-flex>
      <v-flex xs12 sm12>

        <v-card>
          <v-card-title>
            
            <div class="flex-grow-1"></div>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="logs"
            :search="search"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            expand-icon="fa-caret-down"
            item-key="rowid"
            :items-per-page="itemsperpage"
            sort-by="submission_date"
            :sort-desc="sortdesc"
          >
            <template v-slot:expanded-item="{ headers }">
              <td :colspan="headers.length">
                  <issue :activeissue="activeIssue" v-on:update="update" v-on:close="close"></issue>
              </td>
            </template>   
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Issue from '@/components/pieces/Issue';

  export default {
    components:{
      "issue":Issue,
    },
    data: () => ({
      valid: true,
      dialog:false,
      sortdesc:true,
      itemsperpage:25,
      expanded:[],
      singleExpand: true,
      search:"",
      headers:[
          {
            text: 'Submission Date',
            align: 'left',
            sortable: true,
            value: 'submission_date',
          },
          {text:'Rep', value:'rep_email'},
          { text: 'Email', value: 'email' },
          { text: 'Account Number', value: 'account' },
          { text: 'Issue Type', value: 'issue' },
          {
            text: 'Issue Date',
            align: 'left',
            sortable: true,
            value: 'issue_date',
          },
          { text: 'Status', value: 'status' }
        ],
      logs:[],
      checkbox: false,
      user:false,
      code:0
    }),
    created(){
      console.log(this);
      this.$store.watch(() => this.$store.getters['loginStatus'],(data) => {
        // Callback here
        this.status = data;
        if(!this.status){
          this.$router.push({ path: '/login' })
        }
      });
      if(!this.$store.getters['loginStatus']){
        this.$router.push({ path: '/login' });
      }

      console.log("GO GET THE THING");

      console.log("HRRRMMMRM");
      let api = this.$store.getters['apiurl']+"issues/list";
      console.log("API URL",api);
      this.dialog=true;
      this.axios.get(api).then((response) => {
        this.dialog=false;
        if(response.data.code == 200){
          console.log("GOOD DATA",response.data);
          this.logs = response.data.data;

        }else{
          this.user = false;
          this.code = 404;
        }
        console.log(response.data)
      })
    },
    computed:{
      activeIssue(){
        if(this.expanded.length == 0){
          return {};
        }
        return this.expanded[0];
      }
    },
    methods: {
      validate () {
        
      },
      close () {
        
      },
      resetValidation () {

      },
      update(user){
        console.log("Updating Issue ",user);
        let api = this.$store.getters['apiurl']+"issues/update";
        this.dialog=true;
        this.axios.post(api,{data:JSON.stringify(user)}).then((response) => {
          this.dialog=false;
          if(response.data.code == 200){
            console.log("GOOD DATA",response.data);
            this.logs = response.data.data;
            this.$emit('alert','Ticket Updated');
          }else{
            this.$emit('error',response.data.code+": "+response.data.message);
            console.log("SOME ERROR");
          }
        })
      }
    }
  }
</script>

<style>

</style>
