<template>
  <v-container>
    <v-layout
      wrap
    >
        <v-flex xs12 pa-3>
        <h1>Piano User Search</h1>
        <p>Use this interface to search for a user's Gigya account</p>
      </v-flex>
      <v-flex xs12 sm12>

        <v-card>
          <v-card-title>
            
            <div class="flex-grow-1"></div>
            <v-radio-group v-model="searchMode" row>
              <v-radio label="Email" value="email"></v-radio>
              <v-radio label="Circ ID" value="circID"></v-radio>
            </v-radio-group>
            <v-text-field
              v-model="search"
              label="Email Address"
              single-line
              hide-details
            ></v-text-field>
            <v-btn color="success" @click="searchUser"><v-icon>fa-search</v-icon> Search</v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="logs"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            item-key="uid"
          >
            <template v-slot:expanded-item="{ headers }">
              <td :colspan="headers.length">
                  <subscriber :uid="activeUID" v-on:cancel="cancel"></subscriber>
              </td>
            </template>   
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Subscriber from '@/components/pieces/SubscriberPreview';

  export default {
    components:{
      "subscriber":Subscriber,
    },
    data: () => ({
      valid: true,
      dialog:false,
      expanded:[],
      singleExpand: true,
      search:"",
      searchMode:"email",
      headers:[
          { text: 'Email', value: 'email' },
          { text: 'Name', value: 'name' }
        ],
      logs:[],
      checkbox: false,
      user:false,
      code:0
    }),
    created(){
      console.log(this);
      this.$store.watch(() => this.$store.getters['loginStatus'],(data) => {
        // Callback here
        this.status = data;
        if(!this.status){
          this.$router.push({ path: '/login' })
        }
      });
      if(!this.$store.getters['loginStatus']){
        this.$router.push({ path: '/login' });
      }

      console.log("GO GET THE THING");

      console.log("HRRRMMMRM");
      
    },
    computed:{
      activeUID(){
        if(this.expanded.length == 0){
          return {};
        }
        return this.expanded[0].uid;
      }
    },
    methods: {
      searchUser(){
        console.log("SEARCH USER",this.search);
        //let apiurl = "http://localhost/~nate/pg/vantage/api";
        let api = apiurl+"/search/general/"+this.searchMode+"/"+encodeURIComponent(this.search);
        console.log("API URL",api);
        this.dialog=true;
        this.axios.get(api).then((response) => {
          this.dialog=false;
          if(response.data.code == 200){
            console.log("GOOD DATA",response.data);
            this.logs = this.processLogs(response.data.data);

          }else{
            this.user = false;
            this.code = 404;
          }
          console.log(response.data)
        })



      },
      validate () {
        
      },
      reset () {
        
      },
      resetValidation () {

      },
      processLogs(user){
        var logs = [];
        //var u = {};
        user.name = user.first_name+" "+user.last_name;
        user.profile = {};
        for(var x in user.custom_fields){
          var field = user.custom_fields[x];
          user.profile[field.fieldName] = field.value;
        }
        /*for(var x in logs){
          let log = logs[x];
          log.name = 
          log.email = log.email;
          let d = new Date(log.lastUpdatedTimestamp);
          // Hours part from the timestamp
          let hours = d.getHours();
          // Minutes part from the timestamp
          let minutes = "0" + d.getMinutes();
          // Seconds part from the timestamp
          let seconds = "0" + d.getSeconds();
          let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

          log.lastUpdated = d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate()+" "+formattedTime;
        }*/
        logs.push(user);

        console.log("LOGS",logs);
      
        return logs;
      },
      cancel(){
        
      }
    }
  }
</script>

<style>

</style>
