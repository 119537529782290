<template>
  <v-container>
    <v-layout
      wrap
      v-show="subscriber.EmailAddress"
    >
      <v-flex xs12 sm3 pa-3>
        <v-card>
        <div class="text-center pa-3">
          <p class="text-center"><v-icon size='80' center>fa-user-circle</v-icon></p>
          <h3>{{subscriber.Name}}</h3>
          <div class="text-muted">{{subscriber.EmailAddress}}</div>
        </div>

        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Created</v-list-item-title>
              <v-list-item-subtitle>{{subscriber.Date}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Status</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.State}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Email Client</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.ReadsEmailWith}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
        </v-list>
        <v-card-title>Newsletters <v-btn class="mx-2" fab dark small color="primary" icon @click="preferencesdialog=true">
              <v-icon dark>
                fa-pencil
              </v-icon>
            </v-btn></v-card-title>
        <v-dialog v-model="preferencesdialog" scrollable persistent max-width="800px" transition="dialog-transition">
          <!--template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on" text>Edit Newsletters</v-btn>
            <v-btn class="mx-2" fab dark small color="primary" icon v-on="on">
              <v-icon dark>
                fa-pencil
              </v-icon>
            </v-btn>
          </template-->
          <v-card>
            <v-card-title>
              <span class="headline">Edit Newsletters</span>
            </v-card-title>
            <v-card-text>
              <preferences :activeuser="subscriber" v-on:cancel="cancel"></preferences>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="passwordClose">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-list dense>
          <v-list-item v-for="item in subscriber.CustomFields" v-bind:key="item.Key"> 
            <v-list-item-title>{{item.Key}}</v-list-item-title>
            <v-list-item-subtitle>{{item.Value}}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
      </v-flex>
      <v-flex xs12 sm9 sm12 pa-3>
        <v-card elevation=0>
      <v-card-text>
        <v-card-title>Campaign History</v-card-title>
        <v-simple-table fixed-header height="600px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Campaign
                </th>
                <th class="text-left">
                  Opened
                </th>
                <th class="text-left">
                  Engaged
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in subscriber.mailHistory"
                :key="item.ID"
              >
                <td>{{ item.Name }}</td>
                <td>{{ item.opened }}</td>
                <td>{{ item.engaged }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-card-text>
      </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="toast"
      :multi-line="false"
      :top="true"
      color="success"
    >
      {{ snackbarText }}
      <v-btn
        color="white"
        text
        @click="toast = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbar"
      :multi-line="false"
      :top="true"
      color="error"
    >
      {{ snackbarText }}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
     
</template>

<script>
  import Preferences from '@/components/pieces/Preferences'
  
  export default {
    props:['email'],
    components:{
      "preferences":Preferences,
    },
    data: () => ({
      activatevalid:false,
      heading:"Link Account",
      valid: true,
      evalid:true,
      dialog:false,
      toast:false,
      snackbar:false,
      preferencesdialog:false,
      emailchangedialog:false,
      snackbarText:"Invalid Form",
      activatedialog:false,
      accountnumber:"",
      password:"",
      confirm:"",
      newemail:"",
      error:"",
      fieldCipher:{},
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be greater than 10 characters'
      ],
      confirmRules: [
        v => !!v || 'Confirm the changed password',
        v => (v && v.length >= 6) || 'Password must be greater than 10 characters'
      ],
      subscriber:{data:{},profile:{}},
      custAccount:{},
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      accountRules: [
        v => !!v || 'Account # is required',
        v => (v && Boolean(v.match(/^[0-9]*$/))) || 'Account # must be numbers only',
      ],
      custmessage:"Enter a valid cust account number"
    }),
    created(){
      console.log("UID IS ",this.uid)
      //let apiurl = "http://localhost/~nate/pg/vantage/api";
      //let api = apiurl+"search/general/uid/"+this.uid;
      //let api = "https://apis.post-gazette.com/cm_email/subscriber/get/"+encodeURIComponent(this.email)+"/?history_mode=true";
      let api = "http://customerservice.post-gazette.com/api/email/get/"+encodeURIComponent(this.email)+"/?history_mode=true";
      console.log("API URL",api);
      this.dialog=true;
      this.axios.get(api).then((response) => {
        this.dialog=false;
        if(response.data.code == 200){
          console.log("GOOD DATA",response.data);
          //this.logs = this.processLogs(response.data.data);
          this.subscriber = response.data.data;
          this.subscriber.mailHistory = [];
          for(var x in this.subscriber.history){
            var entry = this.subscriber.history[x];
            entry.opened = "No";
            entry.engaged = "No";
            for(var y in entry.Actions){
              console.log(entry.Actions[y].Event);
              if(entry.Actions[y].Event==="Open"){ entry.opened="Yes";}
              if(entry.Actions[y].Event==="Click"){ entry.engaged="Yes";}
            }
            //this.subscriber.mailHistory[entry.Name] = field.value;
            this.subscriber.mailHistory.push(entry);
          }
          this.subscriber.newsletters = {};
          

          this.subscriber.pianoLink = "https://dashboard.piano.io/publisher/user/edit/subscribers/overview/"+this.subscriber.uid;
        }else{
          this.user = false;
          this.code = 404;
        }
        console.log(response.data)
      })

      //let api2 = "http://localhost/pg/api88/email/customFields/list";
      //let api2="https://apis.post-gazette.com/cm_email/customFields/list/";
      let api2="http://customerservice.post-gazette.com/api/email/customFields/list/";
      this.axios.get(api2).then((response) => {
        this.dialog=false;
        if(response.data.code == 200){
          this.buildCipher(response.data.data);
        }else{
          this.user = false;
          this.code = 404;
        }
      });

    },
    methods: {
      getcustaccount () {
        console.log("get account",this.accountnumber);
        //let apiurl = "http://localhost/~nate/pg/vantage/api";
        let api = apiurl+"/search/cuid/"+this.accountnumber;
        console.log("API URL",api);
        this.dialog=true;
        this.error="";
        this.axios.get(api).then((response) => {
          this.dialog=false;
          if(response.data.code == 200){
            console.log("GOOD DATA",response.data);
            //this.logs = this.processLogs(response.data.data);
            let acc = response.data.data.pop();
            console.log(acc)
            this.custAccount = acc;
            this.heading = "Is This User Correct?"
          }else{
            this.custAccount = {};
            this.code = 404;
            console.log("ERROR",response.data.message);
            this.error = response.data.message
          }
          console.log(response.data)
        })
      },
      postData(url = ``, data = {}){
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Content-Type": "application/json",
                "Authorization":localStorage.getItem("_pg_cs_access"),
                "From":localStorage.getItem("_pg_cs_email")
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(response => response.json()); // parses JSON response into native Javascript objects 
      },
      linkClose(){
        this.activatedialog = false;
        this.accountnumber="";
        this.custAccount = {};
        this.error="";
      },

      passwordClose(){
        this.preferencesdialog = false;
      },
      emailClose(){
        this.emailchangedialog = false;
        this.newemail="";
        this.error="";
      },
      incorrectAccount(){
        this.custAccount = {};
        this.error="";
      },
      buildCipher (fields) {    
        console.log("FIELDS",fields);

        for(var x in fields){
            let field = fields[x];
            this.fieldCipher[field.Key] = field.FieldName;
        }

      },
      cancel () {
       
      },
      reset () {
        this.linkClose();
        this.passwordClose();
        this.emailClose();

      },
      resetValidation () {

      },
      gotouser(){
        console.log("GO TO USER ",this.uid);
      },
      validate () {
        this.error = false;
        if (!this.$refs.form.validate()) {
          this.snackbar = true
        }

        if(this.password !== this.confirm){
          console.error("Passwords Do Not Match")
          this.snackbar = true;
          this.error = true;
          this.snackbarText="Passwords Do Not Match";
        }else{
          let api = this.$store.getters['apiurl']+"user/password/reset";
          this.dialog=true;
          console.log("Changing password to ",this.password," from ", this.confirm);
          this.postData(api,{email:this.subscriber.profile.email,pass:this.password}).then((response) => {
            console.log("YO",response);
            this.dialog=false;
            if(response.code == 200){
              //this.subscriber = response.data;
              this.toast=true;
              this.snackbarText="Password Updated";
              this.reset();
            }else{
              this.user = false;
              this.code = 404;
              this.snackbar = true;
              this.snackbarText = response.message;
            }
            console.log(response.message)
          })
        }
      },
      validateEmail () {
        this.error = false;
        if (!this.$refs.eform.validate()) {
          this.snackbar = true;
          return false;
        }


        let api = this.$store.getters['apiurl']+"user/email/change";
        this.dialog=true;
        this.postData(api,{email:this.subscriber.profile.email,newemail:this.newemail,UID:this.subscriber.UID}).then((response) => {
          this.dialog=false;
          console.log("YO",response);
          if(response.code == 200){
            this.subscriber = response.data;
            this.toast=true;
            this.snackbarText="Email Successfully Changed";
            this.reset();
            //this.reset();
          }else{
            this.user = false;
            this.code = 404;
            this.snackbar = true;
            this.snackbarText = response.message;
          }
          console.log(response.message)
        })
      }
    }
  }
</script>

<style>

</style>
