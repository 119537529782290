import Vue from 'vue'
import Vuex from 'vuex'

export const ACCESS_KEY = "_pg_cs_access";
export const AUTH_KEY = "_pg_cs_auth";
export const STATUS_KEY = "_pg_cs_lstatus";
export const LDATE_KEY = "_pg_cs_ldate";
export const EMAIL_KEY = "_pg_cs_email";
export const PROFILE_KEY = "_pg_cs_profile";

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    loggedIn: window.localStorage.getItem(STATUS_KEY) === "true",
	access_token:window.localStorage.getItem(ACCESS_KEY) || '',
	auth_token:window.localStorage.getItem(AUTH_KEY) || '',
	email:window.localStorage.getItem(EMAIL_KEY) || '',
	login_date:window.localStorage.getItem(LDATE_KEY) || '',
	powerusers:['nmason@post-gazette.com','brossi@post-gazette.com','fshaulis2@post-gazette.com','fshaulis@post-gazette.com','rjsmith@toledoblade.com','tzeller@toledoblade.com'],
	developers:['nmason@post-gazette.com'],
	mode:"pg",
	settings:{
		"pg":{
			apiurl:"http://customerservice.post-gazette.com/api/",
			logo:'require(\'../assets/logo2.svg\')',
			syncronexStem:'https://syncaccess-po-pit.syncronex.com/admin/Po/Pit'
		},
		"blade":{
			apiurl:"http://customerservice.toledoblade.com.php73-38.phx1-2.websitetestlink.com/api/",
			logo:"http://customerservice.toledoblade.com.php73-38.phx1-2.websitetestlink.com/img/logo2.d6238af4.svg",
			syncronexStem:'https://syncaccess-po-tbl.syncronex.com/admin/Po/Tbl'
		},
		"bladelocal":{
			apiurl:"http://localhost/blade/vantage/content/api/",
			logo:"http://customerservice.toledoblade.com.php73-38.phx1-2.websitetestlink.com/img/logo2.d6238af4.svg",
			syncronexStem:'https://syncaccess-po-tbl.syncronex.com/admin/Po/Tbl'
		},
		"pgbeta":{
			apiurl:"http://customerservicebeta.post-gazette.com/api/",
			logo:'require(\'../assets/logo2.svg\')',
			syncronexStem:'https://syncaccess-po-pit.syncronex.com/admin/Po/Pit'
		},
		"pglocal":{
			apiurl:"http://localhost/vantage/dev/api/",
			logo:'require(\'../assets/logo2.svg\')',
			syncronexStem:'https://syncaccess-po-pit.syncronex.com/admin/Po/Pit'
		},

	}
  },
  mutations: {
    saveLoginDate(state,ldate){
		state.login_date = ldate; 
	},

	saveProfile(state,profile){
		state.profile = profile;
		state.loggedIn = true;
	},

	logout(state,profile){
		state.profile = {};
		state.loggedIn = false;
		window._CSAPP.lib.auth.logout();
	},

	saveAuth(state,token){
		state.auth_token = token;
	},

	saveAccess(state,token){
		state.access_token = token;
	},
	saveMode(state,token){
		state.mode = token;
	},
	refresh(state){
		state.profile=JSON.parse(window.localStorage.getItem(PROFILE_KEY) || '{}');
		state.loggedIn=window.localStorage.getItem(STATUS_KEY) === "true";
		state.access_token=window.localStorage.getItem(ACCESS_KEY) || '';
		state.auth_token=window.localStorage.getItem(AUTH_KEY) || '';
		state.email=window.localStorage.getItem(EMAIL_KEY) || '';
		state.login_date=window.localStorage.getItem(LDATE_KEY) || '';
	}
  },
  getters: {
    loginStatus(state, getters){
		return state.loggedIn;
	},
	access(state, getters){
		return state.access_token;
	},
	auth(state,getters){
		return state.auth_token;
	},
	email(state,getters){
		return state.email
	},
	loginDate(state,getters){
		return state.login_date;
	},
	isAdmin(state,getters){
		return state.powerusers.indexOf(state.email) >= 0;
	},
	isDev(state,getters){
		return state.developers.indexOf(state.email) >= 0;
	},
	apiurl(state,getters){
		return state.settings[state.mode].apiurl;
	},
	mode(state,getters){
		return state.mode;
	},
	logo(state,getters){
		return state.settings[state.mode].logo;
	},
	syncronexStem(state,getters){
		return state.settings[state.mode].syncronexStem;
	}
  }
})