<template>
 

    <v-card elevation=0>
      <v-card-title><small>Status: <span :class="activeClass">{{activeissue.status}}</span></small></v-card-title>
      <v-card-text>
        <v-layout row wrap>
            <v-flex xs12 sm6>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Submission Date</v-list-item-title>
                    <v-list-item-subtitle>{{activeissue.submission_date}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Customer Email</v-list-item-title>
                    <v-list-item-subtitle>{{activeissue.email}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Rep Email</v-list-item-title>
                    <v-list-item-subtitle>{{activeissue.rep_email}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Account #</v-list-item-title>
                    <v-list-item-subtitle>{{activeissue.account}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list dense>
                <v-list-item> 
                  <v-list-item-content>
                    <v-list-item-title>Issue Type</v-list-item-title>
                    <v-list-item-subtitle>{{activeissue.issue}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Product</v-list-item-title>
                    <v-list-item-subtitle v-text="activeissue.product"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Plan Code</v-list-item-title>
                    <v-list-item-subtitle v-text="activeissue.device"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Transaction ID</v-list-item-title>
                    <v-list-item-subtitle v-text="activeissue.Browser"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 sm6>
            <v-sheet class="pa-4">
              <h4>Problem Description</h4>
              {{activeissue.description}}

            </v-sheet>
          </v-flex>
          <v-flex xs12 sm6>
            <v-sheet class="pa-4">
            <h4>Troubleshooting Steps</h4>
            {{activeissue.troubleshooting}}
            </v-sheet>
          </v-flex>
          <v-flex xs12 sm6>
            <v-sheet class="pa-4">
              <h4>Rep Notes</h4>
              {{activeissue.notes}}

            </v-sheet>
          </v-flex>
          <v-flex xs12 sm6>
            <v-sheet class="pa-4">
              <h4>Feature Request</h4>
              {{activeissue.feature_request}}

            </v-sheet>
          </v-flex>
          
        </v-layout>
        
      </v-card-text>
      <v-card-actions>


      <v-dialog v-model="dialog" persistent max-width="800">
        <template v-slot:activator="{ on }">
          <v-btn color="red" text dark v-on="on">Update Status</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Status</v-card-title>
          <v-card-text>
            <v-form>
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  :items="statuses"
                  label="Outlined style"
                  :value="status"
                  outlined
                ></v-select>
              </v-col>
              <v-textarea
                v-model="response"
                auto-grow
                filled
                flat="flat"
                label="Product Team Response"
                rounded
                rows="5"
                solo
              ></v-textarea>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel</v-btn><v-spacer></v-spacer><v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false; update()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
    </v-card>
     
</template>

<script>
  //import TrialUser from '@/components/pieces/TrialUser'
  /*
{
"rowid": "A26:O26",
"rownum": 26,
"submission_date": "Sep 27, 2019 12:30:44",
"rep_email": "amberlybush@post-gazette.com",
"account": "1136484",
"email": "doncarter@cmu.edu",
"issue": "Other",
"product": "Other",
"device": "Other: unknown",
"browser": "Other: unknown",
"issue_date": "Sep 27, 2019",
"description": "Changing an email it only changed it in Gigya but not in Syncronex",
"troubleshooting": "Checked Gigya and Syncronex; Tried to change the email address; it changed in Gigya but not Syncronex.",
"notes": "Changing email from beacarter314@gmail.com to doncarter@cmu.edu",
"feature_request": "",
"response": "",
"status": "Open"
}
  */
  export default {
    props:['activeissue'],
    data: () => ({
      valid: true,
      dialog:false,
      response:"",
      status:"",
      statuses:[
        "Open",
        "Closed",
        "Awaiting Customer",
        "Awaiting Vendor",
        "Awaiting Rep"
      ]
    }),
    created(){
       this.status = this.activeissue.status;
    },
    computed:{
      activeClass(){
        switch(this.activeissue.status.toLocaleLowerCase()){
          case "open":
            return "red--text";
            break;
          case "awaiting customer":
            return "orange--text";
            break;
          case "closed":
            return "green--text";
            break;
          case "Awaiting Vendor":
            return "purple--text"
            break;
        }

      } 
    },
    methods: {
      validate () {
        
      },
      update () {
        console.log("Before Update Issue",this.activeissue);
        this.activeissue.status = this.status;
        this.activeissue.response = this.response;
        console.log("Post Update Issue",this.activeissue);
        this.$emit('update',this.activeissue);
      },
      resetValidation () {

      }
    }
  }
</script>

<style>

</style>
