<template>
 

    <v-card elevation=0>
      <v-card-title>{{activeuser.Name}}</v-card-title>
      <v-card-text>
        <v-layout row wrap>
            <v-flex xs12 sm6>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Email Address</v-list-item-title>
                    <v-list-item-subtitle>{{activeuser.email_address}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>State</v-list-item-title>
                    <v-list-item-subtitle>{{activeuser.Status}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Active Lists</v-list-item-title>
                    <v-list-item-subtitle v-for="newsletter in newsletters">{{newsletter}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
        </v-layout>
        
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" text dark @click="gotouser">Manage Subscriber</v-btn>
    </v-card-actions>
    </v-card>
     
</template>

<script>
  export default {
    props:['activeuser',"cipher"],
    data: () => ({
      valid: true,
      dialog:false,
      newsletters:[]
    }),
    created(){
      console.log(this.activeuser.CustomFields)
      console.log(this.cipher);
      let exempt = ["Source","Status","email_address","Permission to Track","Name","Date Status Changed","Date Joined","Subscriber Type"]
      for(var x in this.activeuser){

          if(!exempt.includes(x) && this.activeuser[x].length > 0 && this.activeuser[x]==="true"){
              this.newsletters.push(x);
          }
          
      }
      console.log(this.newsletters);

    },
    methods: {
      validate () {
        
      },
      gotouser(){
        console.log("GO TO USER ",this.uid);
        this.$router.push({ name: 'emailuser.view', params: { email: this.activeuser.email_address } })
      },
      resetValidation () {

      }
    }
  }
</script>

<style>

</style>
