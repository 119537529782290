<template>
  <v-container>
    <v-layout
      wrap
    >
        <v-flex xs12 pa-3>
        <h1>Log Viewer</h1>
        <p>Use this interface to view logs from the system.  This should be hidden unless you know where this is</p>
      </v-flex>
      <v-flex xs12 sm12>

        <h3>Error Logs</h3>
        <v-list>
          <v-list-item v-for="log in logs.error" :to="{ name:'pglogs.view',params:{log:log,property:property}}" exact>
            <v-list-item-content>
              <v-list-item-title v-text="log"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <h3>Access Logs</h3>
        <v-list>
          <v-list-item v-for="log in logs"  :to="{name:'pglogs.view',params: {log:log,property:property}}" exact>
            <v-list-item-content>
              <v-list-item-title v-text="log"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      valid: true,
      dialog:false,
      logs:{error:[],info:[]},
      checkbox: false,
      user:false,
      code:0
    }),
    created(){
      console.log(this);
      this.$store.watch(() => this.$store.getters['loginStatus'],(data) => {
        // Callback here
        this.status = data;
        if(!this.status){
          this.$router.push({ path: '/login' })
        }
      });
      if(!this.$store.getters['loginStatus']){
        this.$router.push({ path: '/login' });
      }

      console.log("GO GET THE THING");

      console.log("HRRRMMMRM");
      let api = this.$store.getters['apiurl']+"pglogs/"+this.property+"/view";
      console.log("API URL",api);
      this.dialog=true;
      this.axios.get(api).then((response) => {
        this.dialog=false;
        if(response.data.code == 200){
          console.log("GOOD DATA",response.data);
          this.logs = response.data.data;

        }else{
          this.user = false;
          this.code = 404;
        }
        console.log(response.data)
      })
    },
    computed:{
      property(){
        return this.$route.params.property;
      }
    },
    methods: {
      validate () {
        
      },
      reset () {
        
      },
      resetValidation () {

      }
    }
  }
</script>

<style>

</style>
