<template>
  <v-container>
    <v-layout
      wrap
    >
        <v-flex xs12 pa-3>
        <h1>Deletion Requests</h1>
        <p>Use this interface to easily view all deletion requests received in the past 72 hours</p>
      </v-flex>
      <v-flex xs12 sm12>

        <v-card>
          <v-card-text>
        <v-card-title>Requests</v-card-title>
        <v-simple-table fixed-header height="600px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  User ID
                </th>
                <th class="text-left">
                  Email
                </th>
                <th class="text-left">
                  Date Requested
                </th>
                <th class="text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody v-if="deletions.length > 0">
              <tr
                v-for="item in deletions"
                :key="item.ID"
              >
                <td>{{ item.userID }}</td>
                <td>{{ item.email }}</td>
                <td>{{ new Date(parseInt(item.dateRequested)*1000).toLocaleString() }}</td>
                <td>
                  <v-btn
                    color="red lighten-2"
                    dark
                    @click="selectDeletion(item)"
                  >
                    Remove User
                  </v-btn>
                            
                  
                  
                  
                  
                  
              
                
                
                
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="deletions.length == 0">
              <tr><td colspan="5" class="text-center h2">No Outstanding Deletion Requests</td></tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
        v-model="delete_dialog"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Delete User {{selected_item.email}}
          </v-card-title>

          <v-card-text>
            By clicking this button, you will be adding the user  to the deletion queue.  They will be deleted within 72 hours.   If you've done this by mistake, please contact the product team.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn @click="dialog=false">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="confirmDelete(selected_item)"
            >
              Confirm Deletion
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Issue from '@/components/pieces/Issue';

  export default {
    components:{
      "issue":Issue,
    },
    data: () => ({
      valid: true,
      dialog:false,
      delete_dialog:false,
      selected_item:false,
      sortdesc:true,
      itemsperpage:25,
      expanded:[],
      deletions:[],
      singleExpand: true,
      search:"",
      headers:[
          {
            text: 'Submission Date',
            align: 'left',
            sortable: true,
            value: 'submission_date',
          },
          {text:'Rep', value:'rep_email'},
          { text: 'Email', value: 'email' },
          { text: 'Account Number', value: 'account' },
          { text: 'Issue Type', value: 'issue' },
          {
            text: 'Issue Date',
            align: 'left',
            sortable: true,
            value: 'issue_date',
          },
          { text: 'Status', value: 'status' }
        ],
      logs:[],
      checkbox: false,
      user:false,
      code:0
    }),
    created(){
      console.log(this);
      this.$store.watch(() => this.$store.getters['loginStatus'],(data) => {
        // Callback here
        this.status = data;
        if(!this.status){
          this.$router.push({ path: '/login' })
        }
      });
      if(!this.$store.getters['loginStatus']){
        this.$router.push({ path: '/login' });
      }

      console.log("GO GET THE THING");

      console.log("HRRRMMMRM");
      let api = this.$store.getters['apiurl']+"deletions/list";
      console.log("API URL",api);
      this.dialog=true;
      this.axios.get(api).then((response) => {
        this.dialog=false;
        if(response.data.status == "ok"){
          console.log("GOOD DATA",response.data);
          this.deletions = response.data.items;

        }else{
          this.user = false;
          this.code = 404;
        }
        console.log(response.data)
      })
    },
    computed:{
      activeIssue(){
        if(this.expanded.length == 0){
          return {};
        }
        return this.expanded[0];
      }
    },
    methods: {
      validate () {
        
      },
      close () {
        
      },

      selectDeletion(item){
        this.selected_item=item;
        this.delete_dialog=true;
      },
      getDeletions(){
        let api = this.$store.getters['apiurl']+"deletions/list";
        console.log("API URL",api);
        this.dialog=true;
        this.axios.get(api).then((response) => {
          this.dialog=false;
          if(response.data.status == "ok"){
            console.log("GOOD DATA",response.data);
            this.deletions = response.data.items;

          }else{
            this.user = false;
            this.code = 404;
          }
          console.log(response.data)
        })
      },
      resetValidation () {

      },
      update(user){
        console.log("Updating Issue ",user);
        let api = this.$store.getters['apiurl']+"issues/update";
        this.dialog=true;
        this.axios.post(api,{data:JSON.stringify(user)}).then((response) => {
          this.dialog=false;
          if(response.data.code == 200){
            console.log("GOOD DATA",response.data);
            this.logs = response.data.data;
            this.$emit('alert','Ticket Updated');
          }else{
            this.$emit('error',response.data.code+": "+response.data.message);
            console.log("SOME ERROR");
          }
        })
      },
      confirmDelete(item){
        console.log("TRY TO DELETE ",item)
        let api = this.$store.getters['apiurl']+"deletions/confirm/";
        this.delete_dialog=false;
        this.dialog=true;
        this.axios.post(api,{data:JSON.stringify(item)}).then((response) => {this.dialog=false;
          console.log(response)
          if(response.data && response.data.code == 200){
            console.log("GOOD DATA",response.data);
            this.$emit('alert','Ticket Updated');
            this.dialog=false;
            this.getDeletions();
          }else{
            this.$emit('error',response.data);
            console.log("SOME ERROR");
            this.getDeletions();
          }
        })
      }
    }
  }
</script>

<style>

</style>
