<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="loginStatus"
    >
      <v-list dense v-model="rte" nav>
        <div v-for="(item, i) in navlinks">
          <v-list-item v-if="!item.children && notRestricted(item)" @click="" :to="{ name: item.path }" :key="i" exact>
            <v-list-item-action>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{item.text}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            no-action
            sub-group
            v-if="item.children && notRestricted(item)"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{item.text}}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, j) in item.children"
              :key="j"
              link
              :to="{ name: child.path }"
              exact
            >
              <v-list-item-icon>
                <v-icon v-text="child.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="child.text"></v-list-item-title>
              
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
      <v-list dense v-model="rte" nav v-if="isAdmin">
        <v-list-item v-for="(item, i) in adminLinks" v-if="notRestricted(item)" @click="" :to="{ name: item.path }" :key="i" exact>
          <v-list-item-action>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{item.text}}</v-list-item-title>
          </v-list-item-content>
          <v-list-group
            no-action
            sub-group
            v-if="item.children"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{item.text}}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, j) in item.children"
              :key="j"
              link
              :to="{ name: child.path }"
            >
              <v-list-item-title v-text="child.text"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="child.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </v-list-item>
      </v-list>
      
      <v-list dense v-model="rte" nav v-if="isDev">
        <v-list-item v-for="(item, i) in devLinks"  v-if="notRestricted(item)" @click="" :to="{ name: item.path }" :key="i" exact>
          <v-list-item-action>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{item.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="black"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"><v-icon>fa-bars</v-icon></v-app-bar-nav-icon>
      <div class="flex-grow-1"></div>
      <v-toolbar-title>Vantage</v-toolbar-title>
      <v-spacer></v-spacer>
      
    </v-app-bar>

    <v-content>
      <router-view></router-view>
    </v-content>
    <v-footer
      color="black"
      app
      right
    >
      <span class="white--text">&copy; {{year}}</span>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
      rte:'hasdfasdf',
      navlinks: [
        {
          text: 'Home',
          path: 'home',
          icon: 'fa-home',
          description: ''
        },
        {
          text: 'Subscriber Lookup',
          path: 'subscribers.list',
          icon: 'fa-id-card',
          description: 'Lookup a user\'s profile via the subscriber file.  Useful for finding a user\'s syncronex link'
        },
        
        
        {
          text: 'Email Subscribers',
          path: 'emailuser.list',
          icon: 'fa-users',
          description: 'Email Subscribers',
          restrictions:["pg","pgbeta","pglocal"]
        },
        
        {
          text: 'Deletion Requests',
          path: 'deletion.list',
          icon: 'fa-user-slash',
          description: 'Deletion Requests'
        }
      ],
      adminLinks: [
        {
          text: 'Promo URL Generator',
          path: 'promo.view',
          icon: 'fa-search-dollar',
          description: 'Create Promo Urls for the Generator',
          restrictions:["pg","pgbeta","pglocal"]
        },
        {
          text: 'Deletion Queue',
          path: 'deletionqueue.list',
          icon: 'fa-user-times',
          description: 'Deletion Queue'
        }
      ],
      devLinks: [
        {
          text: 'User Management',
          path: 'users.list',
          icon: 'fa-users',
          description: 'Manage Vantage Users',
          restrictions:["pg","pgbeta","pglocal"]
        },
        {
          text: 'Logs',
          path: 'logs.list',
          icon: 'fa-file-search',
          description: ''
        },
        {
          text: 'Support Issues',
          path: 'issues.list',
          icon: 'fa-ticket-alt',
          description: 'Lookup a user\'s profile via the subscriber file.  Useful for finding a user\'s syncronex link',
          restrictions:["pg","pgbeta","pglocal"]
        }
      ]
    }),
    methods:{
      notRestricted(item){
        if(!item.restrictions){
          return true;
        }else{
          return item.restrictions.includes(this.apimode);
        }
      }
    },
    computed:{
      year(){
        return new Date().getFullYear();
      },
      apimode(){
        return this.$store.getters['mode'];
      },
      isAdmin(){
        return this.$store.getters['isAdmin'];
      },
      isDev(){
        return this.$store.getters['isDev'];
      },
      loginStatus(){
        return this.$store.getters['loginStatus'];
      }
    }
  }
</script>
