<template>
  <v-container class="login">
    <v-card>
      <v-card-title primary-title>
        {{ msg }}
      </v-card-title>
      <v-card-text v-show="!status && mode == 'login'">
      
         <v-text-field
            label="Email Address"
            v-model="email"
            :rules="emailRules"
            required
          ></v-text-field>

          <v-text-field
                  name="input-10-2"
                  label="Password"
                  type="password"
                  v-model="password"
                  :rules="passwordRules"
                  required
          ></v-text-field>

          <v-text-field
              name="input-10-2"
              label="Name"
              type="text"
              v-model="name"
              :rules="nameRules"
              required
          ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn primary dark large v-on:click="checkLogin()">Login</v-btn>
          </v-card-actions>
    </v-card>

    <v-snackbar
      v-model="error"
      :multi-line="false"
      :top="true"
      color="error"
    >
      Error {{ errorcode }}: {{ errormsg }}
      <v-btn
        color="white"
        text
        @click="error = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-show="!status  && mode == 'reset'">
       <v-text-field
        label="Email Address"
        v-model="email"
        :rules="emailRules"
        required
      ></v-text-field>
      <div style="text-align:right"><a v-on:click="mode='login'" href="javascript:;">Back to Login</a></div>
      <div><v-btn primary dark large v-on:click="resetPassword()">Send Reset Email</v-btn></div>
    </div>
    <div v-show="status">
      <div><a href="#profile">My Profile</a></div>
      <div><v-btn primary dark large v-on:click="logout()">Logout</v-btn></div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'login',
  data () {
    return {
      dialog:false,
      error:false,
      email:'',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      name:'',
      nameRules: [
        (v) => !!v || 'Name is required',
      ],
      password:'',
      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
      msg:'Login',
      status:this.$store.getters['loginStatus'],
      mode:'login',
      errorcode:"",
      errormsg:""
    }
  },
  created(){
    this.$store.watch(() => this.$store.getters['loginStatus'],(data) => {
      // Callback here
      this.status = data;
      if(this.status){
        this.$router.push({ path: '/' })
      }
    })
    if(this.$store.getters['loginStatus']){
      this.$router.push({ path: '/' });
    }
  },
  methods:{
    checkLogin:function(){
      var that = this;
      that.dialog = true;
      window._CSAPP.eventer.listen("error",function(err,thing,other){
        if(err.detail.type==="failedLogin"){
          console.log("FAILED LOGIN ",err);
          that.dialog = false;
          that.errorcode = err.detail.data.code;
          that.errormsg = err.detail.data.message;
          that.error = true;
        }
        


      });

      if(!this.errors || !this.errors.items || this.errors.items.length == 0){
        window._CSAPP.lib.auth.login(this.email,this.password,this.name);
      }else{
        console.log("THERE IS AN ERROR");
      }
      
    },
    resetPassword:function(){
      var that = this;
      if(!this.errors || !this.errors.items || this.errors.items.length == 0){
        window._CSAPP.eventer.listen("password.resent",function(ev,data){
          that.$emit("notification","A Password Reset Email Has Been Sent to the submitted email address");
        })
        window._CSAPP.lib.auth.resend(this.email);

      }else{
        console.log("THERE IS AN ERROR");
      }
      
    },
    logout(){
      window._CSAPP.lib.auth.logout();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
