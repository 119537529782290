<template>
 

    <v-card elevation=0>
      <v-card-title>{{subscriber.first_name}} {{subscriber.last_name}}</v-card-title>
      <v-card-text>
        <v-layout row wrap>
            <v-flex xs12 sm6>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Email Address</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.email}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Is Print?</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.profile.is_print}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Address Id</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.profile.addressId}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Occupant Id</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.profile.occupantId}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>SUID</v-list-item-title>
                    <v-list-item-subtitle>{{subscriber.profile.sUID}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Piano ID</v-list-item-title>
                    <v-list-item-subtitle v-text="subscriber.uid"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Plan Code</v-list-item-title>
                    <v-list-item-subtitle v-text="subscriber.profile.rateCode"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
        </v-layout>
        
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" text dark @click="gotouser">View User</v-btn>
    </v-card-actions>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    </v-card>
     
</template>

<script>
  //import TrialUser from '@/components/pieces/TrialUser'
  /*

"dateTime": "2019-09-18 14:00:36",
"termAmount": "0",
"token": "059862013",
"transactionId": "154520190918130034889420",
"planId": "80",
"deliveryInst": "",
"customerEmail": "billybob@brian.net",
"sUID": "91526",



"effectiveStartMonth": "",
"effectiveStartDay": "",
"effectiveStartYear": "",
"gUID": "6add2565ee7046ddbb070ca32a5282d9",
"planName": "4 Week Free Trial - Override Rate",
"planCode": "PPGDGDG00O",
"bucket": "",
"credCardMask": "1796",
"credCardType": "MC",
"expirationDateMonth": "11",
"expirationDateYear": "19"
  */
  export default {
    props:['uid'],
    data: () => ({
      valid: true,
      dialog:false,
      subscriber:{data:{},profile:{}}
    }),
    created(){
      console.log("UID IS ",this.uid)
      //let apiurl = "http://localhost/~nate/pg/vantage/api";
      let api = apiurl+"search/general/uid/"+this.uid;
      console.log("API URL",api);
      this.dialog=true;
      this.axios.get(api).then((response) => {
        this.dialog=false;
        if(response.data.code == 200){
          console.log("GOOD DATA",response.data);
          //this.logs = this.processLogs(response.data.data);
          this.subscriber = response.data.data;
          this.subscriber.profile = {};
          for(var x in this.subscriber.custom_fields){
            var field = this.subscriber.custom_fields[x];
            this.subscriber.profile[field.fieldName] = field.value;
          }
          //this.subscriber.syncronexLink = this.$store.getters['syncronexStem']+"/SubscriberDetail/Index/"+this.subscriber.data.sUID;
        }else{
          this.user = false;
          this.code = 404;
        }
        console.log(response.data)
      })

    },
    methods: {
      validate () {
        
      },
      cancel () {
       
      },
      resetValidation () {

      },
      gotouser(){
        console.log("GO TO USER ",this.uid);
        this.$router.push({ name: 'subscriber.view', params: { uid: this.uid } })
      }
    }
  }
</script>

<style>

</style>
