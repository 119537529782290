<template>
 

    <v-card elevation=0 flat>
      <v-card-title><small>{{log.data}}</small></v-card-title>
      <v-card-text>
        <strong>Request Type:</strong> {{log.key}}<br/>
        <strong>Timestamp:</strong> {{log.timestamp}}<br/>
        <strong>gUID:</strong> {{log.gUID}}<br/>
        {{log.data_1}}
        {{log.data_2}}
        </v-card-text>
    </v-card>
     
</template>

<script>
  //import TrialUser from '@/components/pieces/TrialUser'
  /*

"dateTime": "2019-09-18 14:00:36",
"termAmount": "0",
"token": "059862013",
"transactionId": "154520190918130034889420",
"planId": "80",
"deliveryInst": "",
"customerEmail": "billybob@brian.net",
"sUID": "91526",



"effectiveStartMonth": "",
"effectiveStartDay": "",
"effectiveStartYear": "",
"gUID": "6add2565ee7046ddbb070ca32a5282d9",
"planName": "4 Week Free Trial - Override Rate",
"planCode": "PPGDGDG00O",
"bucket": "",
"credCardMask": "1796",
"credCardType": "MC",
"expirationDateMonth": "11",
"expirationDateYear": "19"
  */
  export default {
    props:['log'],
    data: () => ({
      valid: true,
      dialog:false
    }),
    created(){
      
    },
    methods: {
      validate () {
        
      },
      cancel () {
        console.log("CANCEL ACTIVE USER",this.log);
        this.$emit('cancel',this.log);
      },
      resetValidation () {

      }
    }
  }
</script>

<style>

</style>
