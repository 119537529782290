import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import LookupUser from '@/components/LookupUser'
import UpdatePassword from '@/components/UpdatePassword'
import ChangeEmail from '@/components/ChangeEmail'
import Link from '@/components/LinkAccount'
import Login from '@/components/Login'
import Logs from '@/components/Logs'
import Log from '@/components/Log'
import PGLogs from '@/components/PGLogs'
import PGLog from '@/components/PGLog'
import Promo from '@/components/Promo'
import Deletions from '@/components/Deletions'
import DeletionQueue from '@/components/DeletionQueue'
import Issues from '@/components/Issues'
import Users from '@/components/Users'
import Subscribers from '@/components/Subscribers'
import Subscriber from '@/components/Subscriber'
import EmailUsers from '@/components/EmailUsers'
import EmailUser from '@/components/EmailUser'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: HelloWorld,
      meta: { requiresAuth: true }
    },
    {
      path: '/lookup',
      name: 'user.lookup',
      component: LookupUser,
      meta: { requiresAuth: true }
    },
    {
      path: '/updatepass',
      name: 'password.update',
      component: UpdatePassword,
      meta: { requiresAuth: true }
    },
    {
      path: '/changeemail',
      name: 'email.edit',
      component: ChangeEmail,
      meta: { requiresAuth: true }
    },
    {
      path: '/linkaccount',
      name: 'account.link',
      component: Link,
      meta: { requiresAuth: true }
    },
    {
      path: '/logs',
      name: 'logs.list',
      component: Logs,
      meta: { requiresAuth: true }
    },
    {
      path: '/log/:log',
      name: 'logs.view',
      component: Log,
      meta: { requiresAuth: true }
    },
    {
      path: '/pglogs/:property',
      name: 'pglogs.list',
      component: PGLogs,
      meta: { requiresAuth: true }
    },
    {
      path: '/pglog/:property/:log/:type',
      name: 'pglogs.view',
      component: PGLog,
      meta: { requiresAuth: true }
    },
    {
      path: '/promo/',
      name: 'promo.view',
      component: Promo,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { requiresNoAuth: true }
    },
    {
      path: '/issues',
      name: 'issues.list',
      component: Issues,
      meta: { requiresAuth: true }
    },
    {
      path: '/users',
      name: 'users.list',
      component: Users,
      meta: { requiresAuth: true }
    },
    {
      path: '/subscribers',
      name: 'subscribers.list',
      component: Subscribers,
      meta: { requiresAuth: true }
    },
    {
      path: '/subscriber/:uid',
      name: 'subscriber.view',
      component: Subscriber,
      meta: { requiresAuth: true },
      props:true
    },
    {
      path: '/emailusers',
      name: 'emailuser.list',
      component: EmailUsers,
      meta: { requiresAuth: true }
    },
    {
      path: '/emailuser/:email',
      name: 'emailuser.view',
      component: EmailUser,
      meta: { requiresAuth: true },
      props:true
    },
    {
      path: '/deletions',
      name: 'deletion.list',
      component: Deletions,
      meta: { requiresAuth: true }
    },
    {
      path: '/deletionqueue',
      name: 'deletionqueue.list',
      component: DeletionQueue,
      meta: { requiresAuth: true }
    },
  ]
})
