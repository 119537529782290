<template>
      <v-row>
        <v-col cols="8" style="padding:20px 10px 0px">
             {{ newsletter.label }}
        </v-col>
        <v-col cols="3" offset="1" class="text-right" style="padding:0px">
           <v-switch
            v-model="isOn"
            inset
            @change="updatePreference"
          ></v-switch>
        </v-col>
      </v-row>
</template>

<script>
export default {
  name: 'NSSwitch',
  props: {
    newsletter: Object
  },
  data(){
    let chkd = this.$props.newsletter.value ? "checked" : 'notchecked';
    return {
      checked:chkd,
      isOn:this.$props.newsletter.value,
    };
  },
  created(){
    this.checked = this.$props.newsletter.value ? "checked" : 'notchecked';
  },
  methods:{
    updatePreference(ev){
      console.log("UPDATED",this.$props.newsletter,ev);
      let obj = {};
      obj.key = this.$props.newsletter.Key.replace("[","").replace("]","");
      obj.value = ev;
      this.$emit('update-preference',obj);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
small{
  font-size: .77rem;
  color: #777;
}
</style>
